<template>
  <div>
    <a-row :gutter="24" type="flex">
      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 0 }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">
                  Diet Plan List<a-spin v-if="confirmLoading" />
                </h5>
              </a-col>
              <a-col :span="24" :md="12" class="top-right-header">
                <a-button @click="uploadNewContentModal = true"
                  >Add New</a-button
                >
              </a-col>
            </a-row>
          </template>
          <a-table
            :columns="columnData"
            :data-source="dietPlanList"
            :pagination="false"
            :scroll="{ x: true }"
          >
            <template slot="name" slot-scope="text">
              <a>{{ text }}</a>
            </template>
            <template slot="profileImage" slot-scope="row">
              <img :src="row.image" :alt="row.name" width="100" height="100" />
            </template>
            <template slot="uploadBtn" slot-scope="row">
              <div>
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  @click="showDetailsModal(row.details)"
                >
                  <path
                    d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"
                  />
                </svg>
              </div>
            </template>
          </a-table>
        </a-card>
      </a-col>
      <!-- / Projects Table Column -->

      <!--      Update Profile Image-->
      <a-modal
        v-model:uploadNewContentModal="uploadNewContentModal"
        title="Diet plan Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeUploadNewContentModal"
      >
        <a-row>
          <a-col :span="6">
            <div>Title</div>
          </a-col>
          <a-col :span="18">
            <a-input
              v-model:titleEn="dietPlan.title.en"
              placeholder="Title English"
            />
            <div style="margin: 6px 0" />
            <a-input
              v-model:titleMy="dietPlan.title.my"
              placeholder="Title Myanmar"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 6px">
          <a-col :span="6">
            <div>Publish Date</div>
          </a-col>
          <a-col :span="18">
            <a-date-picker
              v-model:selectedDate="selectedDate"
              format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              style="width: 100%"
            />
          </a-col>
        </a-row>

        <a-row style="margin-top: 6px">
          <a-col :span="24">
            <div style="margin-bottom: 5px">Descripiton</div>
          </a-col>
          <a-col :span="24">
            <vue-editor
              v-model="dietPlan.descripiton.en"
              placeholder="English descripiton"
            />
            <div style="margin: 6px 0" />
            <vue-editor
              v-model="dietPlan.descripiton.my"
              placeholder="Myanmar descripiton"
            />
          </a-col>
        </a-row>
      </a-modal>

      <!-- Diet Content Details -->
      <a-modal
        v-model:detailsModal="detailsModal"
        title="Feature Content Details"
        @ok="closeDetailsModal"
        @cancel="closeDetailsModal"
      >
        <div class="content-details">
          <div>
            ID: <span>{{ contentDetails.id }}</span>
          </div>
          <div>
            Publish Date: <span>{{ contentDetails.publish_date }}</span>
          </div>

          <div>Details (English):</div>
          <div v-if="Object.keys(contentDetails).length">
            <div
              v-html="contentDetails.description.en"
              style="border: 1px solid #e8e8e8; padding: 10px"
            ></div>
          </div>

          <div>Details (Myanmar):</div>
          <div v-if="Object.keys(contentDetails).length">
            <div
              v-html="contentDetails.description.my"
              style="border: 1px solid #e8e8e8; padding: 10px"
            ></div>
          </div>
        </div>
      </a-modal>
    </a-row>
  </div>
</template>
<script>
import moment from "moment/moment";
import { sendRequest } from "@/http/axios.method";
import { VueEditor } from "vue2-editor";
import { message } from "ant-design-vue";

const COLUMN_DATA = [
  {
    title: "Title (English)",
    dataIndex: "nameEn",
    class: "font-semibold text-muted",
  },
  {
    title: "Title (Mayanmar)",
    dataIndex: "nameMy",
    class: "font-semibold text-muted",
  },
  {
    title: "Publish Date",
    dataIndex: "publishDate",
    class: "font-semibold text-muted",
  },
  {
    title: "Status",
    dataIndex: "status",
    class: "font-semibold text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "uploadBtn" },
    width: 200,
  },
];

export default {
  name: "DietPlan",
  components: {
    VueEditor,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      columnData: COLUMN_DATA,
      dietPlans: [],
      dietPlanData: [],
      confirmLoading: false,

      uploadNewContentModal: false,
      selectedDate: "",
      fileContent: "",
      dietPlan: {
        title: {
          en: "",
          my: "",
        },
        descripiton: {
          en: "",
          my: "",
        },

        publish_date: "",
      },
      detailsModal: false,
      contentDetails: [],
    };
  },
  created() {
    this.getDietPlanList();
  },
  methods: {
    disabledDate(event) {
      return moment().isSameOrAfter(moment(event));
    },
    getDietPlanList() {
      this.confirmLoading = true;
      sendRequest("get", "admin/diet-plans", {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          console.log(response);
          this.dietPlans = response.data;
          this.dietPlanData = this.dietPlans.map(function (dietPlan, label) {
            return {
              key: label + 1,
              nameEn: dietPlan.title["en"],
              nameMy: dietPlan.title["my"],
              publishDate: dietPlan.publish_date,
              status: dietPlan.status ? "Active" : "Inactive",
              details: dietPlan,
            };
          });
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    onFileChange(e) {
      this.fileContent = e.target.files[0];
    },

    closeUploadNewContentModal() {
      this.confirmLoading = true;
      let formData = new FormData();
      let configHeader = {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.$store.state.authUser.token,
      };
      formData.append("title[en]", this.dietPlan.title.en);
      formData.append("title[my]", this.dietPlan.title.my);
      formData.append("description[en]", this.dietPlan.descripiton.en);
      formData.append("description[my]", this.dietPlan.descripiton.my);
      formData.append("plan_type", "weight_loss");

      formData.append(
        "publish_date",
        moment(this.selectedDate).format("YYYY-MM-DD")
      );

      sendRequest("post", "admin/diet-plans", formData, configHeader)
        .then((response) => {
          if (response.success) {
            this.confirmLoading = false;
            this.uploadNewContentModal = false;
            // this.fileContent = "";
            this.getDietPlanList();
          } else {
            if (response.status_code === 422) {
              message.error(response.message);
            }
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    showDetailsModal(content) {
      this.detailsModal = true;
      this.contentDetails = content;
    },
    closeDetailsModal() {
      this.contentDetails = [];
      this.detailsModal = false;
    },
  },
  computed: {
    dietPlanList: function () {
      return this.dietPlanData;
    },
  },
};
</script>
